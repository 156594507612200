import { VisibilityFilters, ADD_CONTRIBUTION, ADD_Recipe, RECIPES_LOADING_SUCCESS } from '../actions'
import { ADD_RECIPE_PROMPT, ADD_RECIPE_SUBMIT, CHANGE_RECIPE_VALUE, EDIT_RECIPE_PROMPT, CANCEL_RECIPE_PROMPT } from '../actions/recipeActions'
import { combineReducers } from 'redux'
import auth from './authReducer';
import { recipesRef, saveRecipe, storageRef } from '../../api/database';

const uuidv4 = require('uuid/v4');

function recipes(state = {}, action) {
  switch (action.type) {
    default:
      var recipeList = recipesList(state.recipesList, action);
      return Object.assign({}, state, {recipesList: recipeList, 
              currentRecipe: activeRecipe(state.currentRecipe, action, recipeList)});
  }
}

function recipesList(state = {}, action) {
  switch (action.type) {
    case RECIPES_LOADING_SUCCESS:
      return Object.assign({}, action.recipes)
    case ADD_RECIPE_SUBMIT:
      recipes = Object.assign({}, state);
      if(action.payload.activeRecipe.id == undefined)
        action.payload.activeRecipe.id =  uuidv4();
      recipes[action.payload.activeRecipe.id] = action.payload.activeRecipe;
      saveRecipe(action.payload.activeRecipe);
      return recipes;
    default:
    return state; 
    }
}

function uploadFile(value) {
  var guid = uuidv4(); // ⇨ '10ba038e-48da-487b-96e8-8d3b99b6d18a';
  var fileName = (guid);
  var imageRef = storageRef.child(fileName);
  imageRef.put(value);
  return fileName;
}

function activeRecipe(state = {}, action, recipeList) {
  switch(action.type) {
    case EDIT_RECIPE_PROMPT:
      return recipeList[action.payload.recipeId];
    case CHANGE_RECIPE_VALUE:
    var changeValue = {};

    if(action.payload.field.indexOf("mage") > -1) {
      action.payload.value = uploadFile(action.payload.value);
    }
    if(action.payload.subType != undefined) {
      var subEntity = action.payload.subType;
      var subKey = action.payload.subKey;
      changeValue[subEntity] = state[subEntity] != undefined ? Object.assign([], state[subEntity]) : [];
      if(state[subEntity][subKey] == undefined) changeValue[subEntity].push({});
      changeValue[subEntity][subKey][action.payload.field] = action.payload.value;
      if(changeValue[subEntity].length - 2 < action.payload.subKey) {
        changeValue[subEntity].push({});
      }
    } else {
      changeValue[action.payload.field] = action.payload.value;
    }
      return Object.assign({}, state, changeValue);
    default:
      return Object.assign({}, state, {steps: [{}], ingredients: [{}]});
  }
}

function ui(state = {isAdding: false}, action) {
  switch (action.type) {
    case ADD_RECIPE_PROMPT:
      return Object.assign({}, state, {
        isAdding: true,
        isEditing: false
      });
    case ADD_RECIPE_SUBMIT:
      return Object.assign({}, state, {
        isAdding: false,
        isEditing: false

      });
    case EDIT_RECIPE_PROMPT:
      return Object.assign({}, state, {
        isAdding: false,
        isEditing: true
      });
    case CANCEL_RECIPE_PROMPT:
      return Object.assign({}, state, {
        isAdding: false,
        isEditing: false
      });
    default:
      return Object.assign({}, state);
    }
}


const MyDigitalAccountantApp = combineReducers({
  recipes,
  auth,
  ui
})

export default MyDigitalAccountantApp