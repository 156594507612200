import React from "react";
import VisibleRecipeList from "./containers/VisibleRecipeList";
import SignIn from "./components/auth/SignIn";
import AddRecipe from "./containers/AddRecipe";
import requireAuth from "./components/auth/requireAuth";

import { connect } from 'react-redux'
import { addContribution, loadRecipes, authoriseLoggedInUser } from './redux/actions'
import { BrowserRouter, Route } from "react-router-dom";
import "./styles.css";

class InnerTodoApp extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchRecipes(1);
  }

  render() {
    const {} = this.props;
    return (
      <div className="todo-app">
      <div className="header">
        <img className="header-logo-component" src="images/logo/logonotext.png"/>
        <div className="header-logo-component texthorizontal"><img src="images/logo/logotexthorizontal.png"/></div>
      </div>
        <VisibleRecipeList />
        <AddRecipe />
      </div>
    )
  }
}



class AuthenticationContainer extends React.Component {
  render() {
    return (
      <BrowserRouter>
          <div>
          <Route exact path="/" component={SignIn} />
          <Route path="/app" component={requireAuth(OuterTodoApp)} />
          </div>
      </BrowserRouter>
    );
  }
  
  componentDidMount() {
    this.props.reauth();
  }
  
}

const mapDispatchToProps = dispatch => {
  return {
    fetchRecipes: userId => {
      dispatch(loadRecipes(userId))
    },
    reauth: () => {
      dispatch(authoriseLoggedInUser())
    }
  }
}

const mapStateToProps = state => {
  return {
  }
}

const MainApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationContainer)

const OuterTodoApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(InnerTodoApp)

export default MainApp
