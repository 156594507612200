import { connect } from 'react-redux'
import { addRecipeSubmit, showAddRecipePrompt, changeRecipeValue, changeRecipeSubValue, cancelRecipePrompt } from '../redux/actions/recipeActions.js'
import RecipeForm from '../components/RecipeForm'

const mapStateToProps = state => {
  return {
    isAdding: state.ui.isAdding,
    isEditing: state.ui.isEditing,
    isActive: state.ui.isAdding == true || state.ui.isEditing == true,
    name: state.recipes.currentRecipe.name,
    duration: state.recipes.currentRecipe.duration,
    difficulty: state.recipes.currentRecipe.difficulty,
    coverImage: state.recipes.currentRecipe.coverImage,
    ingredients: state.recipes.currentRecipe.ingredients,
    steps: state.recipes.currentRecipe.steps,
    activeRecipe: state.recipes.currentRecipe
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAddInvoke: () => {
      dispatch(showAddRecipePrompt())
    },
    onCancel: () => {
      dispatch(cancelRecipePrompt())
    },
    onAddSubmit: (activeRecipe) => {
      dispatch(addRecipeSubmit(activeRecipe))
    },
    changeCurrentRecipeValue: (field, value) => {
      dispatch(changeRecipeValue(field, value))
    },
    onStepValueChange: (subKey, field, value) => {
      dispatch(changeRecipeSubValue("steps", subKey, field, value))
    },
    onIngredientValueChange: (subKey, field, value) => {
      dispatch(changeRecipeSubValue("ingredients", subKey, field, value))
    }
  }
}

const AddRecipe = connect(
  mapStateToProps,
   mapDispatchToProps
 )(RecipeForm)

export default AddRecipe