import React from 'react'
import PropTypes from 'prop-types'
import Recipe from './Recipe'

class RecipeList extends React.Component {

  render() {
    const { recipes, onRecipeClick } = this.props;
    return <div className = 'Recipe-list'>
    {recipes.map((recipe, index) => (
      <Recipe key = {recipe.id} {...recipe} onClick={() => onRecipeClick(recipe.id)}  />
    ))}
  </div>
  }
}

export default RecipeList