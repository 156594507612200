import { FETCH_USER } from "../actions";
import firebase from "firebase";

export default (state = null, action) => {
  switch (action.type) {
    case FETCH_USER:
      //return true;
      return action.payload;
    default:
      //TODO: turn on auth
      return state;
      //return true;
  }
};