import { connect } from 'react-redux'
import { addContribution, loadRecipes } from '../redux/actions'
import RecipeList from '../components/RecipeList'
import { showExistingRecipePrompt } from '../redux/actions/recipeActions';

const getRecipes = (recipes, filter) => {
//   switch (filter) {
//     case VisibilityFilters.COMPLETED:
//       return todos.filter(t => t.completed)
//     case VisibilityFilters.INCOMPLETE:
// 	  return todos.filter(t => !t.completed)
// 	default:
      return recipes
}

const mapStateToProps = state => {
  return {
	recipes: getRecipes(Object.keys(state.recipes.recipesList).map(function(k){return state.recipes.recipesList[k]}))
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRecipeClick: id => {
      dispatch(showExistingRecipePrompt(id))
    },
    fetchRecipes: userId => {
      dispatch(loadRecipes(userId))
    }
  }
}

const VisibleRecipeList = connect(
  mapStateToProps,
   mapDispatchToProps
 )(RecipeList)

export default VisibleRecipeList