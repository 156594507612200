import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";

import TodoApp from "./TodoApp";

import { createStore, applyMiddleware } from "redux";
import MyDigitalAccountantApp from "./redux/reducers/reducers";
import {rootEpic} from "./redux/epics/epics";
import {createEpicMiddleware} from "redux-observable";
import {loadRecipes} from "./redux/actions"
import { composeWithDevTools } from 'redux-devtools-extension';
const epicMiddleware = createEpicMiddleware();

const store = createStore(
  MyDigitalAccountantApp, {}, composeWithDevTools(applyMiddleware(epicMiddleware)));

epicMiddleware.run(rootEpic);


const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <TodoApp />
  </Provider>,
  rootElement
);

//store.dispatch(loadRecipes(1));
