import * as firebase from "firebase";

import { FirebaseConfig } from "../constants/firebaseConfig";
firebase.initializeApp(FirebaseConfig);

const database = firebase.firestore();
export const recipesRef = database.collection("recipes");
export const storageRef =firebase.storage().ref();

export const authRef = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();

export function saveRecipe(activeRecipe) {
	recipesRef.doc(activeRecipe.id).set(activeRecipe);
}