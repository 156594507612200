import React from 'react'
import PropTypes from 'prop-types'

import * as Labels from '../constants/labels'

class RecipeForm extends React.Component {
  

  constructor() {
    super();

    // this.state = {
    //     isFlexible: false,
    //     name: 'test',
    //     dollarValue: '',
    //     targetDate: '01-01-1900'
    // };
  }

  handleChange(event) {
    this.props.changeCurrentRecipeValue(event.target.name, event.target.files == undefined ? event.target.value : event.target.files[0])
  }

  addOrInvoke(isActive, actionOnInvoke, actionOnAdd) {
    if(isActive) {
      actionOnAdd(this.props.activeRecipe);
    } else {
      actionOnInvoke();
    }
  }

  render() {
    const {isActive, onAddInvoke, onAddSubmit, onCancel, isAdding, isEditing} = this.props;
    return  <React.Fragment>
      <div className = {'action-button cancel-action-button ' + (isActive ? ('activated') : (''))} onClick= {e => onCancel()}
      >Cancel</div>
      
      <div className = {'action-button edit-action-button ' + (isEditing ? ('activated') : (''))} onClick= {e => {
      e.preventDefault()
      this.addOrInvoke(isEditing, onAddInvoke, onAddSubmit)}}
      >Save</div>

      <div className = {'action-button add-action-button ' + (isAdding ? ('activated') : (''))} onClick= {e => {
      e.preventDefault()
      this.addOrInvoke(isAdding, onAddInvoke, onAddSubmit)}}
      >+</div>
      
              <div className = {'add-action-form action-form ' + (isActive ? ('activated') : (''))}>
              <div className = "panel"><h1>Basic Details</h1><label htmlFor="name">Recipe Name</label>
              <input type="text" name="name" id="name" value={this.props.name || ''} 
              onChange={this.handleChange.bind(this)}/>
              <label htmlFor="difficulty">Difficulty (between 1 and 10)</label>
              <input type="number" name="difficulty" id="difficulty" value={this.props.difficulty || 1} 
              onChange={this.handleChange.bind(this)}/>
              <label htmlFor="duration">Duration (in minutes)</label>
              <input type="number" name="duration" id="duration" value={this.props.duration || 0} 
              onChange={this.handleChange.bind(this)}/>
              <label htmlFor="coverImage">Cover Image</label>
              <input type="file" name="coverImage" id="coverImage" accept="image/*"
              onChange={this.handleChange.bind(this)}/></div>
              <div className = "panel">{this.props.steps.map((step, index) => (
                <StepForm key = {index} id = {index} onStepValueChange = {this.props.onStepValueChange} {...step} />
              ))}
               </div>
               <div className = "panel">
               {this.props.ingredients.map((ingredient, index) => (
                <IngredientForm key = {index} id = {index} onIngredientValueChange = {this.props.onIngredientValueChange} {...ingredient} />
              ))}
               </div>

              </div>
            </React.Fragment>
  }
}

class StepForm extends React.Component {

  render() {
    const {stepImage, ingredients, instructionText, onStepValueChange} = this.props;
    return <div className = "ingredient">
      <h1>Step {this.props.id + 1}</h1>
      <label htmlFor="instructionText">Instruction text</label>
      <input type="text" name="instructionText" id="instructionText" value={this.props.instructionText} 
      onChange={(event) => {onStepValueChange(this.props.id, "instruction", event.target.value)}}/>
      <label htmlFor="stepImage">Step Image</label>
      <input type="file" name="stepImage" id="stepImage" accept="image/*"
      onChange={(event) => {onStepValueChange(this.props.id, "stepImage", event.target.files == undefined ? event.target.value : event.target.files[0])}}/>
    </div>
  }
}

class IngredientForm extends React.Component {

  render() {
    const {stepImage, ingredients, instructionText, onIngredientValueChange} = this.props;
    console.log(this.props.id);
    return <div className = "ingredient">
      <h1>Ingredient {this.props.id + 1}</h1>
      <label htmlFor="name">Ingredient Name</label>
      <input type="text" name="name" id="name" value={this.props.name} 
      onChange={(event) => {onIngredientValueChange(this.props.id, "name", event.target.value)}}/>
       <label htmlFor="quantity">quantity</label>
      <input type="number" name="quantity" id="quantity" value={this.props.quantity} 
      onChange={(event) => {onIngredientValueChange(this.props.id, "quantity", event.target.value)}}/>
       <label htmlFor="style">Style</label>
      <input type="text" name="style" id="style" value={this.props.style} 
      onChange={(event) => {onIngredientValueChange(this.props.id, "style", event.target.value)}}/>
       <label htmlFor="units">Units</label>
      <input type="text" name="units" id="units" value={this.props.units} 
      onChange={(event) => {onIngredientValueChange(this.props.id, "units", event.target.value)}}/>
    </div>
  }
}

export default RecipeForm