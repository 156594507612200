
import { todosRef, authRef, provider } from "../api/database";

export const ADD_CONTRIBUTION = "ADD_CONTRIBUTION";

export const RECIPES_LOADING = "RECIPES_LOADING";
export const RECIPES_LOADING_SUCCESS = "RECIPES_LOADING_SUCCESS";
export const RECIPES_LOADING_ERROR = "RECIPES_LOADING_ERROR";

export const FETCH_TODOS = "FETCH_TODOS";
export const FETCH_USER = "FETCH_USER";
export const SIGN_IN = "SIGN_IN";
export const REAUTH = "REAUTH";


let nextRecipeId = 0;


export const addContribution = (RecipeId, contributionPoints) => ({
  type: ADD_CONTRIBUTION,
  payload: {
    RecipeId, 
    contributionPoints
  }
});

export const loadRecipes = (userId) => ({
  type: RECIPES_LOADING,
  payload: {
    userId
  }
});

export const authoriseLoggedInUser = () => ({
  type: REAUTH,
  payload: {
  }
});


export const signIn = () => ({
  type: SIGN_IN,
  payload: {
  }
});
