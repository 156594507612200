import * as ENV from "../../constants/environment.js"
import {combineEpics} from "redux-observable"
import { todosRef, authRef, provider, recipesRef } from "../../api/database";
import firebase from "firebase"
const { filter, mergeMap, catchError } = require('rxjs/operators');
const redux = require('redux');
const fetch = require('node-fetch');

export const fetchEpic = action$ => action$.pipe(
  filter(action => action.type === 'RECIPES_LOADING' || action.type == 'FETCH_USER'),
  mergeMap(async (action) => {
    var recipes = {};
    //recipes["2221"] = (JSON.parse('{"id":2221, "difficulty":5,"duration":60,"image":"img.jpg","ingredients":[{"name":"onion","quantity":0.5,"style":"diced","units":"self"}],"name":"Chicken Curry","steps":[{"image":"img2.jpg","ingredients":"oil","instruction":"preheat pan to medium heat"}]}'));
    await recipesRef.get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
             
          recipes[doc.id] = Object.assign({}, doc.data(), {id: doc.id});
      });
    });

    return Object.assign({}, action, { type: 'RECIPES_LOADING_SUCCESS', recipes: recipes });
  })
  ,
  catchError(err => Promise.resolve({ type: 'RECIPES_LOADING_ERROR', message: err.message }))
);

export const reauthEpic = action$ => action$.pipe(
  filter(action => action.type === 'REAUTH'),
  mergeMap(async (action) => {
    var newState = {};

    var p = new Promise(function(resolve, reject) {
      authRef.onAuthStateChanged(user => {
        console.log("whats gonna happen")
        if (user) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });

    
    await p.then(x => newState = Object.assign({}, action, { type: 'FETCH_USER', payload: x }));

    return newState;
  }
  )
)

export const authEpic = action$ => action$.pipe(
  filter(action => action.type === 'SIGN_IN'),
  mergeMap(async (action) => {
    const url = ENV.BACKEND_URL;
    var user = null;
    var newState = {};
    await firebase.auth().getRedirectResult().then(result => {
      user = result.user;
    }).catch(error => {
      if (error.code === 'auth/account-exists-with-different-credential') {
        alert('You have already signed up with a different auth provider for that email.');
      } else {
        console.error(error);
      }
    });

    if(user == undefined) {
      const user = await authRef
      .signInWithRedirect(provider)
      .then(result => console.log(result))
      .catch(error => {
        console.log(error);
      });
    } else {
      newState = Object.assign({}, action, { type: 'FETCH_USER', payload: true });
    }

    var authStateChanged = await authRef.onAuthStateChanged(user => {
      console.log("whats gonna happen")
      if (user) {
        console.log("returning")
        
        newState = Object.assign({}, action, { type: 'FETCH_USER', payload: true });
      } else {
        newState = Object.assign({}, action, { type: 'FETCH_USER', payload: null });
      }
    });

    return newState;

    //return Object.assign({}, action, { type: 'FETCH_USER', payload: user })
    
    // console.log("price" + Recipes);
    // debugger;
    //return Object.assign({}, action, { type: 'FETCH_USER', user: user });
  })
  // ,
  // catchError(err => Promise.resolve({ type: 'RECIPES_LOADING_ERROR', message: err.message }))
);

export const rootEpic = combineEpics(
  fetchEpic,
  authEpic,
  reauthEpic
);