export const ADD_RECIPE_PROMPT = "ADD_RECIPE_PROMPT";
export const EDIT_RECIPE_PROMPT = "EDIT_RECIPE_PROMPT";
export const CANCEL_RECIPE_PROMPT = "CANCEL_RECIPE_PROMPT";
export const ADD_RECIPE_SUBMIT = "ADD_RECIPE_SUBMIT";
export const CHANGE_RECIPE_VALUE = "CHANGE_RECIPE_VALUE";


export const showAddRecipePrompt = () => ({
  type: ADD_RECIPE_PROMPT,
  payload: {
  }
});

export const cancelRecipePrompt = () => ({
  type: CANCEL_RECIPE_PROMPT,
  payload: {
  }
});

export const showExistingRecipePrompt = (id) => ({
  type: EDIT_RECIPE_PROMPT,
  payload: {
    recipeId: id
  }
});

export const addRecipeSubmit = (activeRecipe) => ({
	type: ADD_RECIPE_SUBMIT,
	payload: {activeRecipe}
  });

  export const changeRecipeValue = (field, value) => ({
    type: CHANGE_RECIPE_VALUE,
    payload: {
      field : field,
      value : value
    }
    });

  export const changeRecipeSubValue = (subType, subKey, field, value) => ({
    type: CHANGE_RECIPE_VALUE,
    payload: {
      subType : subType,
      subKey : subKey,
      field : field,
      value : value
    }
    });