import React from 'react'
import PropTypes from 'prop-types'
import RecipeAction from './RecipeAction'

import {IsDoingWellFlexible, IsDoingWellEssential, BehindTargetFlexible, BehindTargetEssential} from '../constants/labels'
import { storageRef } from '../api/database';


class Recipe extends React.Component {
  
  renderIngredient(ingredient) {
    if(ingredient.units == "self") {
      return "" + ingredient.quantity + " " + ingredient.name + ((ingredient.style != "") ? (" (" + ingredient.style + ")") : "");
    } else {
      return ingredient.name + ((ingredient.units != "") ? (" (" + ingredient.quantity + " " + ingredient.units + ")") : "") + ((ingredient.style != "") ? (" (" + ingredient.style + ")") : "");

    }
  }
  render() {
    const {difficulty, duration, image, ingredients, name, steps, onClick, id} = this.props;
    // if(image != undefined) {
    //   storageRef.child(image).getDownloadURL().then(function(url) {
    //     var img = document.getElementById(id);
    //     img.src = url;
    //   })
    // }
    return <div className="recipe-container">
      <div className="panel">
        <h1>{name}</h1>
        {//image != undefined ? (<div className = "image"> <img id = {id} src = {storageRef.child(image).getDownloadURL().then} /> </div>) : (<div></div>) 
        }

          <div>{duration} mins</div></div>
      <div className="ingredients panel">{ingredients.filter(x => x.name != undefined).map((x, index) => (<div>{(index + 1) + ": " + this.renderIngredient(x)}</div>))}</div>
      <div className="steps panel">{steps.filter(x => x.instruction != undefined).map((x, index) => (<div>{(index + 1) + ": " + x.instruction}</div>))}</div>
      <button className="edit-button" onClick = {() => onClick(id)}>Edit</button>
    </div>
  }
}

export default Recipe